import React, { useEffect, useState } from "react";
import { Button, Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { CopyOutlined } from "@ant-design/icons";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      message.success("Text copied to clipboard!");
    },
    (err) => {
      console.error("Failed to copy text: ", err);
    }
  );
};

const renderStatus = (value?: any, key?: any) => {
  if (key === "transaction_id") {
    if (value !== null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={value}>
            <div>{value}</div>
          </Tooltip>
          <Button
            icon={<CopyOutlined />}
            onClick={() => copyTextToClipboard(value)}
          ></Button>
        </div>
      );
    }
  }
  if (key === "paymentStatusResponseDateTime") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div className="ellipsis-cell">{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const columns: ColumnsType<DataType> = [
  {
    title: "Transaction Id",
    dataIndex: "transactionId",
    key: "transactionId",
    sorter: (a: any, b: any) =>
      (a?.transactionId?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.transactionId?.toString() ?? "").toLowerCase()),
    width: 200,
    render: (value) => renderStatus(value, "transaction_id"),
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
    sorter: (a: any, b: any) =>
      (a?.companyName?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.companyName?.toString() ?? "").toLowerCase()),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Device Address",
    dataIndex: "deviceAddress",
    key: "deviceAddress",
    sorter: (a: any, b: any) =>
      (a?.deviceAddress?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.deviceAddress?.toString() ?? "").toLowerCase()),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Merchant Id",
    dataIndex: "merchantId",
    key: "merchantId",
    sorter: (a: any, b: any) =>
      (a?.merchantId?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.merchantId?.toString() ?? "").toLowerCase()),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Payment Status Code",
    dataIndex: "paymentStatusCode",
    key: "paymentStatusCode",
    sorter: (a: any, b: any) =>
      (a?.paymentStatusCode?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.paymentStatusCode?.toString() ?? "").toLowerCase()),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Message",
    dataIndex: "paymentStatusMessage",
    key: "paymentStatusMessage",
    sorter: (a: any, b: any) =>
      (a?.paymentStatusMessage?.toString() ?? "")
        .toLowerCase()
        .localeCompare(
          (b?.paymentStatusMessage?.toString() ?? "").toLowerCase()
        ),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Payment Status Payment State",
    dataIndex: "paymentStatusPaymentState",
    key: "paymentStatusPaymentState",
    sorter: (a: any, b: any) =>
      (a?.paymentStatusPaymentState?.toString() ?? "")
        .toLowerCase()
        .localeCompare(
          (b?.paymentStatusPaymentState?.toString() ?? "").toLowerCase()
        ),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Reference Id",
    dataIndex: "paymentStatusProviderReferenceId",
    key: "paymentStatusProviderReferenceId",
    sorter: (a: any, b: any) =>
      (a?.paymentStatusProviderReferenceId?.toString() ?? "")
        .toLowerCase()
        .localeCompare(
          (b?.paymentStatusProviderReferenceId?.toString() ?? "").toLowerCase()
        ),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Date/Time",
    dataIndex: "paymentStatusResponseDateTime",
    key: "paymentStatusResponseDateTime",
    sorter: (a: any, b: any) =>
      (a?.paymentStatusResponseDateTime?.toString() ?? "")
        .toLowerCase()
        .localeCompare(
          (b?.paymentStatusResponseDateTime?.toString() ?? "").toLowerCase()
        ),
    width: 200,
    render: (value) => renderStatus(value, "paymentStatusResponseDateTime"),
  },
];

const RefundData: React.FC = () => {
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [rowData, setRowData] = useState<any>();
  const [transTable, setTransTable] = useState<any>(null);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Admin", href: "/dashboard" },
      { label: "Refund", href: "/refund" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async (values: any) => {
    try {
      const dates = values?.dates.map(
        (date: { format: (arg0: string) => any }) => date.format("DD/MM/YYYY")
      );
      const payLoad = {
        fromDate: dates[0],
        toDate: dates[1],
      };
      const response = await post(apiEndpoints.getAllRefundReport, payLoad);
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  // Function to handle the fetch button click
  const handleFetchClick = async (transactionNo: any) => {
    try {
      if (transactionNo) {
        const payLoad = {
          transactionId: transactionNo,
        };
        const response = await post(
          apiEndpoints.getPaymentTransactionId,
          payLoad
        );
        if (response.status === 200) {
          setTransTable(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitRefund = async (
    transactionNo: any,
    refundMessage: any,
    amount: any
  ) => {
    try {
      const payLoad = {
        transactionId: transactionNo,
        message: refundMessage,
        amount: amount,
      };
      const response = await post(
        apiEndpoints.paymentRefundTransaction,
        payLoad
      );
      if (response.status === 200) {
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={true}
        showDropDowns={false}
        showRefund={true}
        pageName="Refund"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
        onRefundSubmit={handleSubmitRefund}
        fetchTransData={handleFetchClick}
        transactionData={transTable}
      />
    </>
  );
};

export default RefundData;
