import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
  DatePicker,
  Input,
  Switch,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";
import moment from "moment";
import "./page.css";

const ItemForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { itemId } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDateData, setStartDateData] = useState<any>(null);
  const [endDateData, setEndDateData] = useState<any>(null);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Item", href: "/item" },
      {
        label: itemId ? "Edit Item" : "Add Item",
        href: itemId ? `/items/item/${itemId}` : "/item/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      // const payLoad = {
      //   companyId: id,
      // };
      // replace endpoint with location id endpoint to get details
      const response = await get(`${apiEndpoints.getItemMasterById}/${itemId}`);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
        const itemeditData = response?.data["0"];
        form.setFieldsValue(itemeditData);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("add")) {
      fetchById();
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(apiEndpoints.taxlist);
        if (response.status === 200) {
          setTaxList(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };

    fetchData();
    // fetchDd();
  }, []);

  const onFinish = async (values: any) => {
    try {
      // Now use modifiedValues for submission
      // const payLoad: any = { ...values, companyId: companyId };
      const payLoad = {
        ...values,
        taxId: values?.taxId,
        unitPrice: Number(values?.unitPrice),
        // itemId: Number(values?.itemId),
      };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "updateItemMaster",
        addDevice: "createItemMaster",
      };

      const endPoint = itemId ? endPoints.updateDevice : endPoints.addDevice;
      const method = itemId ? put : post;

      // Use the correct template literal for constructing the API endpoint
      const apiUrl = itemId
        ? `${apiEndpoints[endPoint]}/${itemId}`
        : apiEndpoints[endPoint];

      // Await the API method with the correct URL and payload
      const response = await method(apiUrl, payLoad);
      if (response.status === 200) {
        notification.success({
          message: itemId ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/item");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  const onTaxChange = (e) => {
    form.setFieldsValue({ active: e ? 1 : 0 });
  };
  const itemFormMeta: any = [
    // {
    //   name: "itemId",
    //   label: "Item Id",
    //   type: "input",
    //   placeholder: "Enter Item Id",
    //   rules: [
    //     { required: true, readonly: true, message: "This field is required" },
    //     { min: 1, message: "Name must be at least 4 characters" },
    //   ],
    // },
    {
      name: "itemName",
      label: "Item Name",
      type: "input",
      placeholder: "Enter Item Name",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "itemDescription",
      label: "Item Description",
      type: "input",
      // inputType: "number",
      placeholder: "Enter Item Description",
    },
    {
      name: "unitPrice",
      label: "Unit Price",
      type: "number",
      placeholder: "Enter Unit Price Number",
      widgetProps: {
        maxLength: 10,
      },
      rules: [],
    },
    {
      name: "itemPhoto",
      label: <div className="label-as">Item Photo</div>,
      type: "input",
      placeholder: "Enter Photo Name",
      rules: [],
    },
    {
      name: "taxInclusive",
      label: "Tax Inclusive",
      render: (form: any) => (
        <Form.Item
          name="taxInclusive"
          label="Tax Inclusive"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(e) => onTaxChange(e)}
          />
        </Form.Item>
      ),
    },
    {
      name: "taxId",
      label: "Tax",
      type: "select",
      placeholder: "Select Tax",
      // rules: [{ required: true, message: "Select a Tax Rate!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: taxList.map((c: any) => ({
          value: c.taxId,
          label: c.taxName,
        })),
      },
    },
  ];

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          className="item-form"
        >
          {/* <FormBuilder meta={companyFormMeta} form={form} /> */}
          <Row gutter={16}>
            {itemFormMeta.map((item: any, index: any) => (
              <Col span={8} key={index}>
                <FormBuilder meta={[item]} form={form} />
              </Col>
            ))}
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {itemId ? "Update Item" : "Add Item"}
            </Button>
            <Button onClick={() => navigate("/item")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ItemForm;
