const getRoleValue = (role: string) => {
  switch (role) {
    case "ROLE_ADMIN":
      return "admin";
    case "ROLE_ZONAL":
      return "zonal";
    case "ROLE_MASTER":
      return "master";
    case "ROLE_OPERATOR":
      return "operator";
    case "ROLE_RENTALSPACE":
      return "rentalspace";
    case "ROLE_FRANCHISE":
      return "franchise";
    default:
      return null;
  }
};

const getApiEndpoints = (userRole: string) => {
  const dynamicEndPoint = getRoleValue(userRole);

  return {
    getDevices: `/${dynamicEndPoint}/api/v1/device/getAllDevicesByCompany`,
    updateDevice: `/${dynamicEndPoint}/api/v1/device/updateDevice`,
    addDevice: `/${dynamicEndPoint}/api/v1/device/addDevice`,
    deleteDevice: `/${dynamicEndPoint}/api/v1/device/deleteDevice`,
    findAllDeviceClassGroups: `/${dynamicEndPoint}/api/v1/deviceclassgroup/findAllDeviceClassGroups`,
    companyDeviceGroupId: `/${dynamicEndPoint}/api/v1/companygroup/getAllCompanyGroupByCompany`,
    getByDeviceId: `/${dynamicEndPoint}/api/v1/device/getByDeviceId`,

    // dashboard
    getDashboardData: `/${dynamicEndPoint}/api/v1/dashboard/getDashboardTopData`,
    // get juiceman data
    getJuiceManData: `/${dynamicEndPoint}/api/v1/juiceman/getJuiceManDataBetweenDates`,
    getJuiceManDataByAllParams: `/${dynamicEndPoint}/api/v1/juiceman/getJuiceManDataByAllParams`,
    //
    getFCMMobileDevices: `/${dynamicEndPoint}/api/v1/fcm/getFCMMobileDevices`,
    sendNotification: `/${dynamicEndPoint}/api/v1/fcm/sendNotification`,
    // getMessagesByDates
    getMessagesByDates: `/${dynamicEndPoint}/api/v1/message/getMessagesByDates`,
    // sku
    getAllSKUMasters: `/${dynamicEndPoint}/api/v1/sku/getAllSKUMasters`,
    addSKUMaster: `/${dynamicEndPoint}/api/v1/sku/addSKUMaster`,
    updateSKUMaster: `/${dynamicEndPoint}/api/v1/sku/updateSKUMaster`,
    deleteSKUMaster: `/${dynamicEndPoint}/api/v1/sku/deleteSKUMaster`,
    getSKUMasterById: `/${dynamicEndPoint}/api/v1/sku/getSKUMasterById`,
    // company
    getAllCompanies: `/${dynamicEndPoint}/api/v1/company/getAllCompanies`,
    deleteCompanyMaster: `/${dynamicEndPoint}/api/v1/company/deleteCompanyMaster`,
    updateCompanyMaster: `/${dynamicEndPoint}/api/v1/company/updateCompanyMaster`,
    addCompanyMaster: `/${dynamicEndPoint}/api/v1/company/addCompanyMaster`,
    getCompanyMasterId: `/${dynamicEndPoint}/api/v1/company/getCompanyMasterById`,
    getCompanyTypesUnderCompany: `/${dynamicEndPoint}/api/v1/comptypes/getCompanyTypesUnderCompany`,
    getAllCompanyTypes: `/${dynamicEndPoint}/api/v1/comptypes/getAllCompanyTypes`,
    getCompanyDetailsById: `/${dynamicEndPoint}/api/v1/company/getCompanyDetailsById`,
    // states
    getAllStates: `/${dynamicEndPoint}/api/v1/states/getAllStates`,
    getDistrictsByStateId: `/${dynamicEndPoint}/api/v1/district/getDistrictsByStateId/3`,
    // user
    registerUser: `/${dynamicEndPoint}/api/v1/usermanager/registerUser`,
    getAllUserCompany: `/${dynamicEndPoint}/api/v1/usermanager/getAllUserCompany`,
    getAllUserRoles: `/${dynamicEndPoint}/api/v1/roles/getAllUserRoles`,
    getUserManagerById: `/${dynamicEndPoint}/api/v1/usermanager/getUserManagerById/`,
    //
    getRolesByCompanyId: `/${dynamicEndPoint}/api/v1/roles/getRolesByCompanyId`,
    // payments
    getPaymentReportsByAllParams:
      "/phonepay/api/v1/payment/getPaymentReportsByAllParams",
    // new enpoint for devices in reports
    getDevicesByCompanyId: `/${dynamicEndPoint}/api/v1/device/getDevicesByCompanyId`,
    // refund
    getPaymentTransactionId: "/phonepay/api/v1/payment/getPaymentTransactionId",
    // refund grid
    getAllRefundReport: "phonepay/api/v1/payment/getAllRefundReport",
    // message refund
    paymentRefundTransaction:
      "phonepay/api/v1/payment/paymentRefundTransaction",
    // locationss
    getAllLocationsByCompany: `/${dynamicEndPoint}/api/v1/location/getAllLocationsByCompany`,
    addLocationMaster: `/${dynamicEndPoint}/api/v1/location/addLocationMaster`,
    updateLocationMaster: `/${dynamicEndPoint}/api/v1/location/updateLocationMaster`,
    getLocationByLocationId: `/${dynamicEndPoint}/api/v1/location/getLocationByLocationId`,
    // machines
    getAllMachinesByCompanyId: `/${dynamicEndPoint}/api/v1/machine/getAllMachinesByCompanyId`,
    getAllMachineTypes: `/${dynamicEndPoint}/api/v1/machinetypes/getAllMachineTypes`,
    addMachineMaster: `/${dynamicEndPoint}/api/v1/machine/addMachineMaster`,
    // new device
    getDevicesByMachineId: `/${dynamicEndPoint}/api/v1/device/getDevicesByMachineId`,
    // payment
    getAllActivePaymentProviders: `/${dynamicEndPoint}/api/v1/payment/getAllActivePaymentProviders`,
    // dashboard
    getDashboardTopDataByCompany: `/${dynamicEndPoint}/api/v1/dashboard/getDashboardTopDataByCompany`,
    getOrderCountByDays: `/${dynamicEndPoint}/api/v1/dashboard/getOrderCountByDays`,
    getRevenueByDay: `/${dynamicEndPoint}/api/v1/dashboard/getRevenueByDay`,
    getRevenueByStoreId: `/${dynamicEndPoint}/api/v1/dashboard/getRevenueByStoreId`,
    getSalesSummaryByCompanyId: `/${dynamicEndPoint}/api/v1/dashboard/getSalesSummaryByCompanyId`,
    getGlassesSummaryReportByCompanyId: `/${dynamicEndPoint}/api/v1/dashboard/getGlassesSummaryReportByCompanyId`,
    // orders
    getOrdersListByCompanyId: `/${dynamicEndPoint}/api/v1/orders/getOrdersListByCompanyId`,
    getOrdersSummaryByCompanyId: `/${dynamicEndPoint}/api/v1/orders/getOrdersSummaryByCompanyId`,
    getOrderFailuresByCompanyId: `/${dynamicEndPoint}/api/v1/orders/getOrderFailuresByCompanyId`,
    getRefundsByCompanyId: `/${dynamicEndPoint}/api/v1/orders/getRefundsByCompanyId`,
    // Sales Summary Table ByCompanyId
    getSalesSummaryTableByCompanyId: `/${dynamicEndPoint}/api/v1/dashboard/getSalesSummaryTableByCompanyId`,
    // events
    getAllEventsByCompanyId: `/${dynamicEndPoint}/api/v1/event/getAllEventsByCompanyId`,
    getAllEventPricePlans: `/${dynamicEndPoint}/api/v1/event/getAllEventPricePlans`,
    getAllActiveOrOldEventsByCompanyId: `/${dynamicEndPoint}/api/v1/event/getAllActiveOrOldEventsByCompanyId`,
    addEventSlotPrice: `/${dynamicEndPoint}/api/v1/event/addEventSlotPrice`,
    addEventMaster: `/${dynamicEndPoint}/api/v1/event/addEventMaster`,
    getEventDetailsByEventId: `/${dynamicEndPoint}/api/v1/event/getEventDetailsByEventId`,
    addEventSlot: `/${dynamicEndPoint}/api/v1/event/addEventSlot`,
    // machine map
    getAllMachineEventsList: `/${dynamicEndPoint}/api/v1/machineevent/getAllMachineEventsList`,
    addMachineEvents: `/${dynamicEndPoint}/api/v1/machineevent/addMachineEvents`,
    getMachineListForEvents: `/${dynamicEndPoint}/api/v1/machineevent/getMachineListForEvents`,
    updateMachineEvents: `/${dynamicEndPoint}/api/v1/machineevent/updateMachineEvents`,
    getMachineEventById: `/${dynamicEndPoint}/api/v1/machineevent/getMachineEventById`,
    deleteMachineEvents: `/${dynamicEndPoint}/api/v1/machineevent/deleteMachineEvents`,
    // machine map get
    getAllMachineDeviceEventsList: `${dynamicEndPoint}/api/v1/machineevent/getAllMachineDeviceEventsList`,
    getListOfCompaniesUnderId: `/${dynamicEndPoint}/api/v1/company/getListOfCompaniesUnderId`,
    // add customer masrter in company
    // addCompanyMaster: `/${dynamicEndPoint}/api/v1/company/addCompanyMaster`,
    getAllItems: `/${dynamicEndPoint}/api/v1/itemmaster/items`,
    deleteItemMaster: `/${dynamicEndPoint}/api/v1/itemmaster/item`,
    getItemMasterById: `/${dynamicEndPoint}/api/v1/itemmaster/item`,
    createItemMaster: `/${dynamicEndPoint}/api/v1/itemmaster/item`,
    updateItemMaster: `/${dynamicEndPoint}/api/v1/itemmaster/item`,
    taxlist: `/${dynamicEndPoint}/api/v1/itemtax/taxlist`,

    getCompanyTypesUnderCompanyId: `/${dynamicEndPoint}/api/v1/comptypes/getCompanyTypesUnderCompanyId`,
    // getCompanyTypesUnderCompany: `/${dynamicEndPoint}/api/v1/comptypes/getCompanyTypesUnderCompany`,

    getOrdersGlassesReportByCompanyId: `/${dynamicEndPoint}/api/v1/dashboard/getOrdersGlassesReportByCompanyId`,
    getAllMachinesByLocationId: `/${dynamicEndPoint}/api/v1/machine/getAllMachinesByLocationId`,
    getOrderFailureSummaryByCompanyId: `/${dynamicEndPoint}/api/v1/orders/getOrderFailureSummaryByCompanyId`,
    // user roles manage
    getAllMappings: `/${dynamicEndPoint}/api/v1/usermapping/getAllMappings`,
    getAllMappingsByUserId: `/${dynamicEndPoint}/api/v1/usermapping/getAllMappingsByUserId`,
    addUserMapping: `/${dynamicEndPoint}/api/v1/usermapping/addUserMapping`,
    updateUserMapping: `/${dynamicEndPoint}/api/v1/usermapping/updateUserMapping`,
    getAllMachineInLocations: `/${dynamicEndPoint}/api/v1/machine/getAllMachineInLocations`,
  };
};

export default getApiEndpoints;
