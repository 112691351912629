import React, { useEffect, useState } from "react";
import { Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, deleteMethod, get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { DeleteOutlined, EditOutlined, FileAddOutlined } from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";
import PricePlans from "../PricePlans/page";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "userName") {
    if (record?.fcmDataID?.userName) {
      return (
        <Tooltip title={record.fcmDataID.userName}>
          <div>{record.fcmDataID.userName}</div>
        </Tooltip>
      );
    }
  }

  if (key === "locationId") {
    if (value !== null) {
      return <CopyClipBoard text={value} />;
    } else {
      return "NA";
    }
  }
  if (key === "locationAddress") {
    if (value !== null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={value}>
            {/* <div>{value}</div> */}
            <div className="ellipsis-cell">{value}</div>
          </Tooltip>
          <MapIcon latitude={record?.latitude} longitude={record?.longitude} />
        </div>
      );
    } else {
      return "NA";
    }
  }
  if (key === "updatedAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const Variants: React.FC = () => {
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Locations", href: "/locations" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      // const payLoad = {
      //   companyId: localStorage.getItem("companyId") || companyId,
      // };
      const response = await get(apiEndpoints.getAllItems);
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const handleVariants = (record: any) => {
    navigate(`/variants/variant/${record.itemId}`);
    console.log(record, "record");
  };

  const handleEdit = (record: any) => {
    navigate(`/items/item/${record.itemId}`);
    console.log(record, "record");
  };

  const handleDeleteConfirmation = async (itemId: string) => {
    try {
      if (itemId) {
        const response = await deleteMethod(
          `${apiEndpoints.deleteItemMaster}/${itemId}`
        );
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          await onSubmit();
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(record?.id);
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Item Id",
      dataIndex: "itemId",
      key: "itemId",
      sorter: (a: any, b: any) =>
        a.itemId
          .toString()
          .toLowerCase()
          .localeCompare(b.itemId.toString().toLowerCase()),
      width: 20,
      // render: renderStatus,
      render: (value) => renderStatus(value, "itemId"),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      sorter: (a: any, b: any) =>
        a.itemName
          .toString()
          .toLowerCase()
          .localeCompare(b.itemName.toString().toLowerCase()),
      width: 25,
      render: renderStatus,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      sorter: (a: any, b: any) =>
        a.desc
          .toString()
          .toLowerCase()
          .localeCompare(b.itemDescription.toString().toLowerCase()),
      width: 40,
      render: (value, record) => renderStatus(value, "itemDescription", record),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      sorter: (a: any, b: any) =>
        a.unitPrice
          .toString()
          .toLowerCase()
          .localeCompare(b.unitPrice.toString().toLowerCase()),
      width: 15,
      render: renderStatus,
    },
     {
      title: "Tax Rate",
       dataIndex: "taxRate",
      key: "taxRate",
       sorter: (a: any, b: any) =>
         a.taxRate
           .toString()
           .toLowerCase()
        .localeCompare(b.taxRate.toString().toLowerCase()),
        width: 15,
       render: renderStatus,
     },
     {
       title: "Tax",
       dataIndex: "taxName",
       key: "taxName",
       sorter: (a: any, b: any) =>
         a.taxName
           .toString()
           .toLowerCase()
           .localeCompare(b.taxName.toString().toLowerCase()),
      width: 30,
       render: renderStatus,
     },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 30,
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
           &nbsp; &nbsp;&nbsp;
          <Tooltip title="Add Variants">
            <FileAddOutlined onClick={() => handleVariants(record)} />
          </Tooltip>{" "}
           &nbsp; &nbsp;&nbsp;
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />
          </Tooltip>{" "}
          &nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <CommonTable
        routePath="/item/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default Variants;
