import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  Button,
  message,
  Select,
  notification,
  Row,
  Col,
  Switch,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  maxLengthValidator,
  minLengthValidator,
  phoneNumberValidator,
  postcodeValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";

const CompanyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [statesDropdown, setStatesDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Company", href: "/company" },
      {
        label: id ? "Edit Company" : "Add Company",
        href: id ? `/company/${id}` : "/company/add",
      },
    ]);
  }, [setBreadCrumb]);

  // const fetchById = async () => {
  //   try {
  //     const payLoad = {
  //       companyId: id,
  //     };
  //     const response = await post(apiEndpoints.getCompanyMasterId, payLoad);
  //     if (response.status === 200) {
  //       console.log(response, "response-response");

  //       const initialValues = {
  //         ...response?.data,
  //         active: response?.data?.isActive === 1 ? true : false,
  //       };
  //       form.setFieldsValue(initialValues);
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };
  const getCompanyDetails = async () => {
    try {
      // const payLoad = {
      //   companyId: id,
      // };
      const response: any = await get(
        apiEndpoints.getCompanyDetailsById + `/${id}`
      );
      if (response.status === 200) {
        const initialValues = {
          ...response?.data?.companyMasterReqData,
          active:
            response?.data?.companyMasterReqData?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("editCompany")) {
      // fetchById();
      getCompanyDetails();
    }
  }, [location]);

  useEffect(() => {
    // const fetchOrg = async () => {
    //   try {
    //     const response = await get(apiEndpoints.getAllCompanies);
    //     if (response && response.status === 200 && response.data) {
    //       setCompanyDropdown(response.data);
    //     }
    //   } catch (error) {
    //     console.log("Error fetching companies:", error);
    //   }
    // };

    // fetchOrg();
    handleData();
  }, []);

  const handleData = async () => {
    try {
      const userNew: any =
        localStorage.getItem("user") || sessionStorage.getItem("user");
      const getData = JSON.parse(userNew);
      const dynamicEndPoint =
        getData.roles[0] === "ROLE_ADMIN"
          ? "getAllCompanyTypes"
          : "getCompanyTypesUnderCompany";
      const responseCompanyTypes = await get(
        `${apiEndpoints[dynamicEndPoint]}`
      );
      if (responseCompanyTypes.status === 200) {
        setCompanyTypes(responseCompanyTypes?.data);
      }
      const dropdownResponse = await get(apiEndpoints.getAllStates);
      if (dropdownResponse.status === 200) {
        setStatesDropdown(dropdownResponse?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  // useEffect(() => {
  //   // Assuming apiData is the data received from the API
  //   // Convert the 'active' field value to a boolean for the Switch
  //   const initialValues = {
  //     ...apiData,
  //     active: apiData.active === "1", // Convert to boolean
  //   };
  //   form.setFieldsValue(initialValues);
  // }, [apiData, form]);

  const onFinish = async (values: any) => {
    try {
      const modifiedValues = {
        ...values,
        active: values.active ? 1 : 0,
      };

      // Now use modifiedValues for submission
      console.log("Form Submit", modifiedValues);
      const payLoad: any = { ...modifiedValues, companyMasterId: companyId };
      setIsLoading(true);
      const endPoints: any = {
        updateCompany: "updateCompanyMaster",
        addCompany: "addCompanyMaster",
      };
      const endPoint: any = id ? endPoints.updateCompany : endPoints.addCompany;
      const method = id ? put : post;
      const response: any = await method(apiEndpoints[`${endPoint}`], payLoad);
      if (response.status === 200) {
        notification.success({
          message: id ? "Updated Successfully" : "Created Successfully",
        });
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  // exzmple to add object in first for the array in edit mode
  //   let array = [2, 3, 4];
  // array.unshift(1); // Adds 1 at the beginning of the array

  const companyFormMeta: any = [
    {
      name: "companyType",
      label: "Type",
      type: "select",
      placeholder: "Select Company Type",
      rules: [{ required: true, message: "Select a Company Type!" }],
      widget: Select,
      widgetProps: {
        options: companyTypes.map((c: any) => ({
          value: c.companyTypeId,
          label: c.companyType,
        })),
      },
    },
    {
      name: "companyName",
      label: "Name",
      type: "input",
      placeholder: "Enter Company Name",
      rules: [{ required: true, message: "This field is required" }],
      disabled: location.pathname.includes("editCompany") ? true : false,
    },
    {
      name: "companyAddress",
      label: "Address",
      type: "input",
      placeholder: "Enter Company Address",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "country",
      label: "Country",
      type: "input",
      placeholder: "Enter Country Name",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "stateName",
      label: "State Name",
      type: "select",
      placeholder: "Select State Name",
      rules: [{ required: true, message: "Select a State Name!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: statesDropdown.map((c: any) => ({
          value: c.stateName,
          label: c.stateName,
        })),
      },
    },
    {
      name: "postalCode",
      label: "Postal Code",
      type: "input",
      inputType: "Number",
      placeholder: "Enter Postal Code",
      widgetProps: {
        maxLength: 6,
      },
      rules: [
        {
          required: true,
          validator: async (value: any) => {
            return postcodeValidator(value);
          },
        },
      ],
    },
    {
      name: "companyCIN",
      label: "CIN",
      type: "input",
      placeholder: "Enter Company CIN",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "companyTin",
      label: "Tin",
      type: "input",
      placeholder: "Enter Company TIN",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "companyGST",
      label: "GST",
      type: "input",
      placeholder: "Enter Company GST",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "companyEmail",
      label: "Email",
      type: "input",
      placeholder: "Enter Company Email",
      rules: [
        {
          validator: async (value: any) => {
            return emailValidator(value, false);
          },
        },
      ],
    },
    {
      name: "companyPhone",
      label: "Phone",
      type: "input",
      inputType: "number",
      placeholder: "Enter Company Phone",
      widgetProps: {
        maxLength: 10,
      },
      rules: [
        {
          validator: async (value: any) => {
            return phoneNumberValidator(value);
          },
        },
        {
          validator: async (value: any) => {
            return maxLengthValidator("Number", 10, value);
          },
        },
        {
          validator: async (value: any) => {
            return minLengthValidator("Number", 10, value);
          },
        },
      ],
    },
    {
      name: "companyContactName",
      label: "Contact Name",
      type: "input",
      placeholder: "Enter Company Contact Name",
    },
    {
      name: "companyContactEmail",
      label: "Contact Email",
      type: "input",
      placeholder: "Enter Company Contact Email",
      rules: [
        {
          validator: async (value: any) => {
            return emailValidator(value, false);
          },
        },
      ],
    },
    {
      name: "companyContactPhone",
      label: "Contact Phone",
      type: "input",
      inputType: "number",
      placeholder: "Enter Company Contact Phone",
      widgetProps: {
        maxLength: 10,
      },
      rules: [
        {
          validator: async (value: any) => {
            return phoneNumberValidator(value);
          },
        },
        {
          validator: async (value: any) => {
            return maxLengthValidator("Number", 10, value);
          },
        },
        {
          validator: async (value: any) => {
            return minLengthValidator("Number", 10, value);
          },
        },
      ],
    },
    {
      name: "companyContactMobile",
      label: "Contact Mobile",
      type: "input",
      inputType: "number",
      placeholder: "Enter Company Contact Mobile",
      widgetProps: {
        maxLength: 10,
      },
      rules: [
        {
          validator: async (value: any) => {
            return phoneNumberValidator(value);
          },
        },
        {
          validator: async (value: any) => {
            return maxLengthValidator("Number", 10, value);
          },
        },
        {
          validator: async (value: any) => {
            return minLengthValidator("Number", 10, value);
          },
        },
      ],
    },

    {
      name: "active",
      label: "Active",
      render: (form: any) => (
        <Form.Item
          name="active"
          label="Active"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="InActive"
            onChange={(checked) => {
              // Convert the boolean to a string and set the form field value
              // const value = checked ? "1" : "0";
              form.setFieldsValue({ active: checked });
            }}
          />
        </Form.Item>
      ),
    },
  ];

  // useEffect(() => {
  //   companyFormMeta.unshift({
  //     name: "companyMasterId",
  //     label: "Master",
  //     type: "select",
  //     placeholder: "Select Company Master",
  //     rules: [{ required: true, message: "Select a Company Master!" }],
  //     disabled: true,
  //   });
  // }, []);

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          className="company-form"
        >
          {/* <FormBuilder meta={companyFormMeta} form={form} /> */}
          <Row gutter={16}>
            {companyFormMeta.map((item: any, index: any) => (
              <Col span={8} key={index}>
                <FormBuilder meta={[item]} form={form} />
              </Col>
            ))}
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {id ? "Update Company" : "Add Company"}
            </Button>
            <Button onClick={() => navigate("/company")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CompanyForm;
