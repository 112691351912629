import React, { useEffect, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { post } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import { companyState } from "../../../lib/stores/companyId";
import CommonTable from "../../../lib/components/Table/table";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CopyOutlined } from "@ant-design/icons";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      message.success("Text copied to clipboard!");
    },
    (err) => {
      console.error("Failed to copy text: ", err);
    }
  );
};

const formatNumber = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);

  return formattedNumber;
};

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

const renderStatus = (value?: any, key?: any, record?: any) => {
  // if (key === "amount") {
  //   const amount = formatNumber(value); // Adjust decimal places as needed
  //   return amount ? `${amount}` : "NA";
  // }
  if (key === "revenue") {
    const amount = formatNumber((record?.amount / 1.12).toFixed(2)); // Adjust decimal places as needed
    return `${amount}`;
  }
  if (key === "gst") {
    const amount = (record.amount / 1.12).toFixed(2);
    const finalGST = Number(record.amount) - Number(amount);
    const GST = formatNumber(finalGST.toFixed(2)); // Adjust decimal places as needed
    return `${GST}`;
  }
  if (key === "average") {
    // Calculate the average if both glassesCount and orderCount are available
    if (
      record.glassesCount != null &&
      record.orderCount != null &&
      record.orderCount !== 0
    ) {
      const average = (record.glassesCount / record.orderCount).toFixed(2); // Adjust decimal places as needed
      return `${average}`;
    } else {
      return "NA"; // Return 'NA' if data is not sufficient
    }
  }

  if (key === "date") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}`;
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div className="ellipsis-cell">{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const columns: ColumnsType<DataType> = [
  {
    title: "Order Date",
    dataIndex: "orderDate",
    key: "orderDate",
    align: "center",
    width: 120,
    sorter: (a: any, b: any) =>
      (a?.orderDate?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.orderDate?.toString() ?? "").toLowerCase()),
    render: (value) => renderStatus(value, "date"),
  },
  {
    title: "Store Id",
    dataIndex: "storeId",
    key: "storeId",
    align: "center",
    width: 130,
    sorter: (a: any, b: any) =>
      (a?.storeId?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.storeId?.toString() ?? "").toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Loc Name",
    dataIndex: "locName",
    key: "locName",
    align: "center",
    width: 150,
    sorter: (a: any, b: any) =>
      (a?.locName?.toString() ?? "")
        .toLowerCase()
        .localeCompare((b?.locName?.toString() ?? "").toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Order Count",
    dataIndex: "orderCount",
    key: "orderCount",
    width: 150,
    align: "center",
    // sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    sorter: (a: any, b: any) => {
      const countA = a?.orderCount ?? 0; // Fallback to 0 if orderCount is null or undefined
      const countB = b?.orderCount ?? 0; // Fallback to 0 if orderCount is null or undefined
      return countA - countB;
    },
    render: (value) => renderStatus(value, "data"),
  },
  {
    title: "Glasses Count",
    dataIndex: "glassesCount",
    key: "glassesCount",
    width: 150,
    align: "center",
    // sorter: (a: any, b: any) => a.glassesCount - b.glassesCount,
    sorter: (a: any, b: any) => {
      const countA = a?.glassesCount ?? 0; // Fallback to 0 if glassesCount is null or undefined
      const countB = b?.glassesCount ?? 0; // Fallback to 0 if glassesCount is null or undefined
      return countA - countB;
    },
    render: renderStatus,
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
    width: 120,
    align: "center",
    sorter: false,
    render: (value, record) => renderStatus(value, "revenue", record),
  },
];

// Activate the customParseFormat plugin to handle specific date formats
dayjs.extend(customParseFormat);
// Format today's date in the desired format
const today = dayjs().format("DD/MM/YYYY");
const loadInitialValues = () => {
  const storedDates = sessionStorage.getItem("selectedDates");
  let formattedDates = [null, null];

  if (storedDates) {
    const dates = JSON.parse(storedDates);
    // Explicitly parse each date with the expected format
    formattedDates = [
      dayjs(dates[0], "DD/MM/YYYY").isValid()
        ? dayjs(dates[0], "DD/MM/YYYY")
        : null,
      dayjs(dates[1], "DD/MM/YYYY").isValid()
        ? dayjs(dates[1], "DD/MM/YYYY")
        : null,
    ];
  } else {
    formattedDates = [dayjs(today, "DD/MM/YYYY"), dayjs(today, "DD/MM/YYYY")];
  }

  const initialValues = {
    Machine: "All",
    Location: "All",
    dates: formattedDates,
  };

  return initialValues;
};

const FailureSummary: React.FC = () => {
  const initialValues = loadInitialValues();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [machineDropdown, setMachines] = useState<any>([]);
  const [locationDropdown, setLocations] = useState<any>([]);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  useEffect(() => {
    setBreadCrumb([
      { label: "Transactions", href: "/dashboard" },
      { label: "Failure Summary", href: "/failure-summary" },
    ]);
  }, [setBreadCrumb]);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchDropdowns = async () => {
      const responseMachine = await post(
        apiEndpoints.getAllMachinesByCompanyId,
        payLoad
      );
      if (
        responseMachine &&
        responseMachine?.status === 200 &&
        responseMachine?.data
      ) {
        setMachines([
          { machineName: "All", machineId: "all" },
          ...responseMachine.data,
        ]);
      }
      const responseLocation = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (
        responseLocation &&
        responseLocation?.status === 200 &&
        responseLocation?.data
      ) {
        setLocations([
          // { locationName: "All", locId: "all" },
          ...responseLocation.data,
        ]);
      }
    };
    fetchDropdowns();
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const dates = values?.dates.map(
        (date: { format: (arg0: string) => any }) => date.format("DD/MM/YYYY")
      );
      const payLoad = {
        fromDate: dates[0],
        toDate: dates[1],
        companyId: localStorage.getItem("companyId") || companyId,
        machineId: values?.Machine === "All" ? null : values?.Machine,
        locationId: values?.Location === "All" ? null : values?.Location,
      };
      const response = await post(
        apiEndpoints.getOrderFailureSummaryByCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const calculateTotals = (data: any) => {
    return data?.reduce(
      (acc, record) => {
        // Update totals for each column
        acc.orderCount += record?.orderCount;
        acc.glassesCount += record?.glassesCount;
        acc.amount += record?.amount;
        acc.revenue += parseFloat((record?.amount / 1.12).toFixed(2)); // Example for revenue, adjust accordingly
        acc.gst += parseFloat(
          (Number(record?.amount) - record?.amount / 1.12).toFixed(2)
        );
        acc.aurobindoRevenue += parseFloat(
          ((record?.amount / 1.12) * 0.1).toFixed(2)
        );
        return acc;
      },
      {
        orderCount: 0,
        glassesCount: 0,
        amount: 0,
        revenue: 0,
        gst: 0,
        aurobindoRevenue: 0,
      } // Initial totals
    );
  };

  const totals = calculateTotals(rowData);

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={true}
        showDropDowns={true}
        pageName="Order-Failure"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        // machines={machineDropdown}
        locations={locationDropdown}
        onFinish={onSubmit}
        // footer={() => (
        //   <div style={{ display: "flex", justifyContent: "space-between" }}>
        //     <b>Total Amount: {formatNumber(totals?.amount)}</b>
        //     <b>Total Order Count: {totals?.orderCount}</b>
        //     <b>Total Glasses Count: {totals?.glassesCount}</b>
        //     <b>Total Revenue: {formatNumber(totals?.revenue.toFixed(2))}</b>
        //     <b>Total GST: {formatNumber(totals?.gst.toFixed(2))}</b>
        //     {/* <b>Aurobindo Revenue: {totals?.aurobindoRevenue.toFixed(2)}</b> */}
        //   </div>
        // )}
      />
    </>
  );
};

export default FailureSummary;
