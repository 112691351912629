import React, { useEffect, useState } from "react";
import { Tag, Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
// import { EditOutlined } from "@ant-design/icons";
// import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
// import { useNavigate } from "react-router-dom";
// import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import { companyState } from "../../../lib/stores/companyId";
// import { companyState } from "../../../lib/stores/companyId";
// import MapIcon from "../../../lib/components/MapLocator/page";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  console.log(record, "record");
  if (key === "isActive") {
    const color = record.isActive === 1 ? "green" : "volcano";
    return (
      <div>
        <Tag color={color}>{record.isActive === 1 ? "Active" : "Inactive"}</Tag>
      </div>
    );
  }
  if (key === "locationId") {
    if (value !== null) {
      return <CopyClipBoard text={value} />;
    } else {
      return "NA";
    }
  }
  if (key === "expiryDate") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const PricePlans: React.FC = () => {
  // const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Price plans", href: "/price-plans" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const response = await post(apiEndpoints.getAllEventPricePlans, payLoad);
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  // const handleEdit = (record: any) => {
  //   navigate(`/users/editUsers/${record.userId}`);
  //   console.log(record, "record");
  // };

  // const handleDeleteConfirmation = async (userId: string) => {
  //   try {
  //     if (userId) {
  //       const response = await del(`${apiEndpoints.deleteSKUMaster}`, {
  //         companyId: localStorage.getItem("companyId") || companyId,
  //         userId: userId,
  //       });
  //       if (response.status === 200) {
  //         notification.success({
  //           message: "Successfully Deleted",
  //         });
  //         await onSubmit();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };

  // const handleDelete = (record: any) => {
  //   DiscardChangeModal(
  //     "Click OK to Confirm to Delete.",
  //     async () => {
  //       handleDeleteConfirmation(record?.userId);
  //     },
  //     () => {},
  //     { title: "Are you sure you want to Delete ?" }
  //   );
  // };

  const columns: ColumnsType<DataType> = [
    {
      title: "Plan Name",
      dataIndex: "planName",
      key: "planName",
      sorter: (a: any, b: any) =>
        (a?.planName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.planName?.toString() ?? "").toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value) => renderStatus(value, "planName"),
    },
    {
      title: "Plan Slab Count",
      dataIndex: "planSlabCount",
      key: "planSlabCount",
      sorter: (a: any, b: any) =>
        (a?.planSlabCount?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.planSlabCount?.toString() ?? "").toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Plan Slab Price",
      dataIndex: "planSlabPrice",
      key: "planSlabPrice",
      sorter: (a: any, b: any) =>
        (a?.planSlabPrice?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.planSlabPrice?.toString() ?? "").toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Plan Min Amount",
      dataIndex: "planMinAmount",
      key: "planMinAmount",
      sorter: (a: any, b: any) =>
        (a?.planMinAmount?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.planMinAmount?.toString() ?? "").toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Additional Sale Price",
      dataIndex: "additionalSalePrice",
      key: "additionalSalePrice",
      sorter: (a: any, b: any) =>
        (a?.additionalSalePrice?.toString() ?? "")
          .toLowerCase()
          .localeCompare(
            (b?.additionalSalePrice?.toString() ?? "").toLowerCase()
          ),
      width: 100,
      render: (value) => renderStatus(value, "additionalSalePrice"),
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 50,
    //   render: (text, record) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Tooltip title="Edit">
    //         <EditOutlined onClick={() => handleEdit(record)} />{" "}
    //       </Tooltip>
    //       &nbsp; &nbsp;&nbsp;
    //       <Tooltip title="Delete">
    //         <DeleteOutlined onClick={() => handleDelete(record)} />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <CommonTable
        routePath="/price-plans/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default PricePlans;
